
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import Layout from "../components/shared/layout"
import { Article, ArticleSideContent } from '../components/shared/articles'
import { PageType } from '../components/shared/seo/PageType';
import { TwittterCardSize } from '../components/shared/seo/CardSize';

const Letter = (props) => {
  const { post } = props.data
  const { fields, frontmatter, html } = post;
  const { slug } = fields;
  const {
    title,
    image,
    description,
    date,
    updated,
    category,
    tags,
    seriesId
  } = frontmatter;

  // const comments = props.data.comments.edges
  //   .filter((c) => slug.indexOf(c.node.url) !== -1)
  //   .map((c) => ({ ...c.node}));

  const allTableOfContents = props.data.allTableOfContents
    .edges.map((e) => JSON.parse(e.node.internal.content))
    .filter((c) => c.seriesId === seriesId);

  const headings = post.headings;

  let seoTags = tags ? tags : [];
  seoTags = seoTags.concat(category);

  return (
    <Layout
      seo={{
        title,
        keywords: seoTags,
        image,
        description,
        pageType: PageType.ARTICLE,
        datePublished: date,
        dateModified: updated,
        slug,
        cardSize: TwittterCardSize.LARGE
      }}
      newsletterContent={
        <>
          <h3>
            Not subscribed? Get advanced dev newsletters straight to your inbox each week.
          </h3>
          <p>
            Join 15,000+ other value-creating React, TypeScript
            & Node.js devs mastering <i>The Essentials</i> of software design, architecture & testing.
          </p>
        </>
      }
    >
      <div className="article-layout-container">
        <Article
          {...fields}
          {...frontmatter}
          html={html}
          // comments={comments}
          headings={headings}
          tableOfContents={allTableOfContents.length !== 0 ? allTableOfContents[0].tableOfContents : []}
        />
        <ArticleSideContent/>
      </div>
    </Layout>
  )
}

Letter.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: PropTypes.string,
        date: PropTypes.string.isRequired,
        category: PropTypes.string,
        description: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
      html: PropTypes.string
    }),
  }),
}

export default Letter

export const pageQuery = graphql`
  query LetterById($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      headings {
        depth
        id
        value
      }
      frontmatter {
        seriesId
        date
        updated
        title
        templateKey
        description
        tags
        image
        category
        anchormessage
        withInlineTableOfContents
      }
    }

    allTableOfContents: allFile(filter: {ext: {eq: ".json"}, name: {eq: "tableOfContents"}}) {
      edges {
        node {
          internal {
            content
          }
        }
      }
    }
  }
`